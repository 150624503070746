<template>
<div class="hero is-fullheight">
    <div class="hero-body m-auto  columns p-0 m-0 is-centered">
        <div class="column has-background-white p-4 is-10-mobile ">
            <h1 class=" is-size-3 has-text-centered mb-4 mt-4">Jesse Rent</h1>
        <input-box
            label="E-mail"
            type="email"
            :modelValue="user.email"
            @update:modelValue="user.email = $event"
            class="is-input-half has-text-black"
            placeholder="E-mail"
          />
        <input-box
            label="Password"
            type="password"
            :modelValue="user.password"
            @update:modelValue="user.password = $event"
            class="is-input-half has-text-black"
            placeholder="Password"
          />
          <div class="is-flex is-align-items-center">
        <button @click="login(user)" class="button is-small is-rounded is-hoverable is-success is-clickable">Log in</button>
        <!-- <router-link class="ml-2" to="/admin/register">
            {{$t('link_sign_up')}}
        </router-link> -->
          </div>
        </div>
    </div>
</div>
</template>
<script>
    import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import router from "../../router";

    export default {
    data() {
        return {
            user: {}
        }
    },
    methods: {
        login(user){

            const auth = getAuth();
            signInWithEmailAndPassword(auth, user.email, user.password)
              .then(() => {
                router.push("/admin")
              })            
            },
            checkUser() {
                const auth = getAuth();
                onAuthStateChanged(auth, (user) => {
                  if (user) {
                    router.push("/Admin")
                  }
                }) 
            }
        },
        mounted() {
            this.checkUser()
    }
}
</script>
<style lang="scss" scoped>
    .hero{
        background-image: url('~@/assets/img/placeholder2.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
</style>